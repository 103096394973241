import classnames from "classnames";
import React from "react";
import { Body } from "./modules/Body/Body";
import { Footer } from "./modules/Footer/Footer";
import { Header } from "./modules/Header/Header";

export const LicenseCard = ({
  className,
  icon,
  label,
  mrp,
  payablePrice,
  tenure,
  info,
  customPrimaryActionJSX,
  totalCartItems,
  isAddedToCart,

  onBuy,
  onLearnMore,
  onAddedInCartClick,

  children,
}) => {
  return (
    <div
      className={classnames(
        "tw-bg-white tw-rounded-[10px] tw-shadow-[0px_2px_12px_0px_rgba(20,20,43,0.08)] tw-flex tw-flex-col tw-h-full",
        className
      )}
    >
      <Header
        icon={icon}
        label={label}
        mrp={mrp}
        payablePrice={payablePrice}
        tenure={tenure}
        onLearnMore={onLearnMore}
      />

      <Body info={info}>{children}</Body>

      <Footer
        totalCartItems={totalCartItems}
        onBuy={onBuy}
        isAddedToCart={isAddedToCart}
        onAddedInCartClick={onAddedInCartClick}
      >
        {customPrimaryActionJSX}
      </Footer>
    </div>
  );
};
