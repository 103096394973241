import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { Button } from "@my-scoot/component-library-legacy";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import {
  LICENSE_TYPES,
  SUBSCRIPTIONS_API_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  StaffMembersLearnMoreModal,
  StaffMembersLicensePurchaseModal,
} from "features/MySubscriptions/constants/MySubscriptions.lazy";
import { LicenseCard } from "features/MySubscriptions/modules/LicenseCard/LicenseCard";
import { StatusBadge } from "features/MySubscriptions/modules/LicenseCard/modules/StatusBadge/StatusBadge";
import { parseLicenseCardData } from "features/MySubscriptions/modules/LicenseCard/utils/parseLicenseCardData";
import { useMySubscriptionsStore } from "features/MySubscriptions/store/useMySubscriptionsStore";
import React from "react";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { useToggleState } from "utils/hooks";

export const StaffMembersLicenseCard = ({ plan, onSeeDetails }) => {
  const { getItemQuantityInCart, handleGoToCart } = useCart();
  const tenure = useMySubscriptionsStore(
    (state) => state.licensesSection.tenure
  );
  const [
    isStaffMembersPurchaseModalOpen,
    openStaffMembersPurchaseModal,
    closeStaffMembersPurchaseModal,
  ] = useToggleState(false);
  const [
    isStaffMembersLearnMoreModalOpen,
    openStaffMembersLearnMoreModal,
    closeStaffMembersLearnMoreModal,
  ] = useToggleState(false);
  const {
    [SUBSCRIPTIONS_API_KEYS.feature_consumption_config]: {
      [SUBSCRIPTIONS_API_KEYS.total_free_credits]: totalFreeCredits = 0,
      [SUBSCRIPTIONS_API_KEYS.total_paid_credits]: totalPaidCredits = 0,
    },
    [SUBSCRIPTIONS_API_KEYS.plans]: plans,
  } = plan;
  const totalActiveCredits = totalFreeCredits + totalPaidCredits;
  const showBadge = !!totalActiveCredits;
  const badgeLabel = `${totalActiveCredits} Active`;
  const totalAddedInCart = getItemQuantityInCart(LICENSE_TYPES.STAFF_MEMBERS);

  const handleBuyLicense = () => {
    openStaffMembersPurchaseModal();
    closeStaffMembersLearnMoreModal();
  };

  const handleSeeDetails = () => {
    onSeeDetails({
      featureKey: LICENSE_TYPES.STAFF_MEMBERS,
      onBuy: handleBuyLicense,
    });
  };

  return (
    <div className="tw-flex-1">
      <LicenseCard
        onBuy={handleBuyLicense}
        onLearnMore={openStaffMembersLearnMoreModal}
        customPrimaryActionJSX={
          <>
            {showBadge ? (
              <StatusBadge label={badgeLabel} onSeeDetails={handleSeeDetails} />
            ) : (
              <div />
            )}

            {totalAddedInCart ? (
              <Button
                color="success"
                className="!tw-h-[33px] !tw-rounded-[5px]"
                onClick={handleGoToCart}
              >
                <CheckRoundedIcon className="!tw-mr-1 !tw-text-base" />{" "}
                {totalAddedInCart} Added in Cart
              </Button>
            ) : (
              <Button
                className="tw-max-h-9 !tw-rounded-md"
                startIcon={<LocalMallOutlinedIcon />}
                color={totalActiveCredits ? "secondary" : undefined}
                variant={totalActiveCredits ? "outlined" : undefined}
                onClick={handleBuyLicense}
                size={totalActiveCredits ? "small" : undefined}
              >
                {totalActiveCredits ? "Buy more license" : "Buy now"}
              </Button>
            )}
          </>
        }
        {...parseLicenseCardData({ plan, tenure })}
      ></LicenseCard>

      {isStaffMembersPurchaseModalOpen && (
        <LazyLoadWrapper loadingCondition={isStaffMembersPurchaseModalOpen}>
          <StaffMembersLicensePurchaseModal
            open={isStaffMembersPurchaseModalOpen}
            onClose={closeStaffMembersPurchaseModal}
            plans={plans}
          />
        </LazyLoadWrapper>
      )}

      <LazyLoadWrapper loadingCondition={isStaffMembersLearnMoreModalOpen}>
        <StaffMembersLearnMoreModal
          open={isStaffMembersLearnMoreModalOpen}
          onClose={closeStaffMembersLearnMoreModal}
          onBuy={handleBuyLicense}
        />
      </LazyLoadWrapper>
    </div>
  );
};
