import React from "react";

export const ZoomLicensePurchaseModal = React.lazy(() =>
  import("../modules/ZoomLicensePurchaseModal/ZoomLicensePurchaseModal").then(
    (module) => ({
      default: module.ZoomLicensePurchaseModal,
    })
  )
);

export const ZoomLicenseLearnMoreModal = React.lazy(() =>
  import("../modules/ZoomLicenseLearnMoreModal/ZoomLicenseLearnMoreModal").then(
    (module) => ({
      default: module.ZoomLicenseLearnMoreModal,
    })
  )
);

export const StaffMembersLearnMoreModal = React.lazy(() =>
  import(
    "../modules/StaffMembersLearnMoreModal/StaffMembersLearnMoreModal"
  ).then((module) => ({
    default: module.StaffMembersLearnMoreModal,
  }))
);

export const StaffMembersLicensePurchaseModal = React.lazy(() =>
  import(
    "../modules/StaffMembersLicensePurchaseModal/StaffMembersLicensePurchaseModal"
  ).then((module) => ({
    default: module.StaffMembersLicensePurchaseModal,
  }))
);

export const WhiteLabellingLearnMoreModal = React.lazy(() =>
  import(
    "../modules/WhiteLabellingLearnMoreModal/WhiteLabellingLearnMoreModal"
  ).then((module) => ({
    default: module.WhiteLabellingLearnMoreModal,
  }))
);

export const WhiteLabellingPurchaseModal = React.lazy(() =>
  import(
    "../modules/LicensesSection/modules/WhiteLabellingLicenseCard/modules/WhiteLabellingPurchaseModal/WhiteLabellingPurchaseModal"
  ).then((module) => ({
    default: module.WhiteLabellingPurchaseModal,
  }))
);

export const CreditsUpdateModal = React.lazy(() =>
  import(
    "../modules/CreditsModal/modules/CreditsUpdateModal/CreditsUpdateModal"
  ).then((module) => ({
    default: module.CreditsUpdateModal,
  }))
);

export const CreditsPurchaseModal = React.lazy(() =>
  import("../modules/CreditsModal/CreditsPurchaseModal").then((module) => ({
    default: module.CreditsPurchaseModal,
  }))
);

export const PlanCta = React.lazy(() =>
  import("../modules/ActivePlanSection/modules/PlanCta/PlanCta").then(
    (module) => ({
      default: module.PlanCta,
    })
  )
);
