import {
  LICENSE_ICONS,
  SUBSCRIPTIONS_API_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";
import { TENURES } from "features/MySubscriptions/constants/MySubscriptions.tenures";

export const parseLicenseCardData = ({ plan = {}, tenure }) => {
  const {
    [SUBSCRIPTIONS_API_KEYS.feature_key]: key,
    [SUBSCRIPTIONS_API_KEYS.feature_name]: name,
    [SUBSCRIPTIONS_API_KEYS.feature_description]: featureDescription,
    [SUBSCRIPTIONS_API_KEYS.plans]: tenurePlans = [],
  } = plan || {};

  const selectedTenurePlan = tenurePlans.find(
    (plan) => plan[SUBSCRIPTIONS_API_KEYS.recurrence_months] === tenure
  );
  const {
    [SUBSCRIPTIONS_API_KEYS.custom_price]: mrp,
    [SUBSCRIPTIONS_API_KEYS.default_price]: payablePrice,
  } = selectedTenurePlan;

  return {
    label: name,
    icon: LICENSE_ICONS[key],
    info: featureDescription,
    mrp,
    payablePrice,
    tenure: tenure === TENURES.MONTHLY ? "/month" : "/year",
  };
};
