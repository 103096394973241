import React from "react";
import classnames from "classnames";

export const SectionHeader = ({ className, label }) => {
  return (
    <div
      className={classnames(
        "tw-mb-[6px] tw-text-[#272522] tw-text-sm max-md:tw-text-xs tw-font-normal tw-leading-[normal] tw-opacity-50",
        className
      )}
    >
      {label}
    </div>
  );
};
