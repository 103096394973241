import { PlanInfoSection } from "../modules/PlanInfoSection/PlanInfoSection";
import { PlanSection } from "../modules/PlanSection/PlanSection";

export const PLAN_SECTION_TYPES = {
  PRIMARY: 1,
  SECONDARY: 2,
};

export const PLAN_SECTION_MOBILE_COLUMNS = 3;

export const PLAN_SECTION_TYPES_COMPONENTS = {
  [PLAN_SECTION_TYPES.PRIMARY]: PlanSection,
  [PLAN_SECTION_TYPES.SECONDARY]: PlanInfoSection,
};

export const PLAN_DETAILS_API_KEYS = {
  current_plan: "current_plan",
  commission_percentage: "commission_percentage",
  date_joined: "date_joined",
  end_datetime: "end_datetime",
  international_internet_handling_percent:
    "international_internet_handling_percent",
  internet_handling_percent: "internet_handling_percent",
  is_downgraded: "is_downgraded",
  is_top_plan: "is_top_plan",
  name: "name",
  overall_commission: "overall_commission",
  prev_plan: "prev_plan",
  start_datetime: "start_datetime",
  invoice_url: "invoice_url",
};
