import { checkIsArrayEmpty } from "features/Common/modules/DataTypes/utils/Array.utils";

export const groupItems = ({ items, chunkSize }) => {
  if (checkIsArrayEmpty(items)) return [];

  const visibleItems = items.filter((item) => !item.hide);
  const chunkedItems = [];

  for (let i = 0; i < visibleItems.length; i += chunkSize) {
    chunkedItems.push({ id: i, items: visibleItems.slice(i, i + chunkSize) });
  }

  return chunkedItems;
};
