import React from "react";
import {
  STATUS_CLASSES,
  STATUS_ICONS,
  STATUS_LABELS,
  SUBSCRIPTION_DETAILS_API_KEYS,
} from "../../constants/SubscriptionsDetailsModal.constants";
import { StatusField } from "../StatusField/StatusField";

export const SubscriptionStatus = ({
  classes,
  record: {
    [SUBSCRIPTION_DETAILS_API_KEYS.status]: status,
    [SUBSCRIPTION_DETAILS_API_KEYS.status_info_icon]: tooltip,
  },
}) => {
  const icon = STATUS_ICONS[status];

  return (
    <StatusField
      icon={icon}
      label={STATUS_LABELS[status]}
      tooltip={tooltip}
      classes={{ label: STATUS_CLASSES[status], ...classes }}
    />
  );
};
