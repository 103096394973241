import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import {
  MY_SUBSCRIPTIONS_CDN_ASSETS_RELATIVE_PATH,
  UBP_FEATURES_CDN_ASSETS_RELATIVE_PATH,
  WHITELABELLING_CDN_ASSETS_RELATIVE_PATH,
} from "../constants/MySubscriptions.constants";

export const getWhitelabellingAssets = (key) => {
  return getS3AssetCDNUrl(`${WHITELABELLING_CDN_ASSETS_RELATIVE_PATH}/${key}`);
};

export const getMySubscriptionsAssets = (key) => {
  return getS3AssetCDNUrl(
    `${MY_SUBSCRIPTIONS_CDN_ASSETS_RELATIVE_PATH}/${key}`
  );
};

export const getUBPFeaturesAssets = (key) => {
  return getS3AssetCDNUrl(`${UBP_FEATURES_CDN_ASSETS_RELATIVE_PATH}/${key}`);
};
