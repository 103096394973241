import { create } from "zustand";

const initialState = {
  selectedContactId: null,
  selectedContact: null,
  contactInfo: null,
  totalUnreadNotifications: 0,
  contactsListLoading: true,
};

export const useInboxStore = create((set) => ({
  ...initialState,
  actions: {
    setContactsListLoading: (value) => set({ contactsListLoading: value }),
    setSelectedContactId: (value) => set({ selectedContactId: value }),
    setSelectedContact: (contact) => set({ selectedContact: contact }),
    setContactInfo: (data) => set({ contactInfo: data }),
    setTotalUnreadNotifications: (value) =>
      set({ totalUnreadNotifications: value }),
    reset: () => set({ ...initialState }),
  },
}));
