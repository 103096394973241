import React from "react";

export const Body = ({ info, children }) => {
  return (
    <div className="tw-px-3 tw-flex-1 tw-pb-2 tw-flex tw-flex-col tw-justify-between">
      <div className="tw-opacity-60 tw-text-[#272522] tw-text-base tw-tracking-[0.32px] tw-leading-[21px]">
        {info}
      </div>

      {children}
    </div>
  );
};
