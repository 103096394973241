import React, { FC } from "react";
import { ILinkTypeTile } from "./interfaces/ILinkTypeTile";
import classnames from "classnames";
import styles from "./LinkTypeTile.module.css";

const LinkTypeTile: FC<ILinkTypeTile> = ({
  title,
  description,
  icon,
  active,
  value,
  onClick,
}) => {
  return (
    <div
      className={classnames(styles.wrapper, active && styles.wrapperActive)}
      onClick={() => onClick(value)}
    >
      <div className={classnames(styles.root, active && styles.active)}>
        <div className={styles.content}>
          <img className={styles.icon} src={icon} alt="" />

          <div className={styles.title}>{title}</div>

          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default LinkTypeTile;
