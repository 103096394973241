import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";

export const OMNICHANNEL_URL_KEYS = {
  inbox: "inbox",
};

export const OMNICHANNEL_ROUTE_IDS = {
  [OMNICHANNEL_URL_KEYS.inbox]: "/communications/inbox",
};

export const INBOX_MAIN_PATH =
  OMNICHANNEL_ROUTE_IDS[OMNICHANNEL_URL_KEYS.inbox];

const Inbox = React.lazy(() =>
  import("features/OmniChannel/modules/Inbox/Inbox").then((module) => ({
    default: module.Inbox,
  }))
);

export const OMNICHANNEL_CUSTOM_ROUTES = [
  <Route
    exact
    key={OMNICHANNEL_ROUTE_IDS[OMNICHANNEL_URL_KEYS.inbox]}
    path={OMNICHANNEL_ROUTE_IDS[OMNICHANNEL_URL_KEYS.inbox]}
    render={(props) => <LazyLoadRoutes props={props} LazyElement={Inbox} />}
  />,
];
