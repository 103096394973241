import React from "react";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import classnames from "classnames";

export const PlanSection = ({
  label,
  value,
  classes = { label: undefined, value: undefined },
}) => {
  return (
    <div>
      <SectionHeader label={label} className={classes?.label} />
      <div
        className={classnames(
          "tw-text-2xl max-md:tw-text-xl tw-font-medium tw-leading-[normal] tw-bg-[radial-gradient(172.23%_206.42%_at_60.45%_42.19%,_#40329A_0%,_#7365CD_100%)] tw-bg-clip-text tw-text-transparent",
          classes?.value
        )}
      >
        {value}
      </div>
    </div>
  );
};
