import { SUBSCRIPTIONS_API_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import { TENURES } from "features/MySubscriptions/constants/MySubscriptions.tenures";

export const getDicount = ({ monthlyPrice, planPrice, planTenure }) => {
  const totalMonthlyCost = monthlyPrice * planTenure;
  return Math.round(((totalMonthlyCost - planPrice) / totalMonthlyCost) * 100);
};

export const getOverallLicensesDicount = (plans) => {
  let totalMonthlyPrice = 0;
  let totalYearlyPrice = 0;

  plans.forEach((plan) => {
    const { [SUBSCRIPTIONS_API_KEYS.plans]: licensePlans } = plan;

    const monthlyPlan = licensePlans.find(
      (plan) =>
        plan[SUBSCRIPTIONS_API_KEYS.recurrence_months] === TENURES.MONTHLY
    );
    const yearlyPlan = licensePlans.find(
      (plan) =>
        plan[SUBSCRIPTIONS_API_KEYS.recurrence_months] === TENURES.YEARLY
    );
    const { [SUBSCRIPTIONS_API_KEYS.default_price]: monthlyPayablePrice } =
      monthlyPlan;
    const { [SUBSCRIPTIONS_API_KEYS.default_price]: yearlyPayablePrice } =
      yearlyPlan;

    totalMonthlyPrice += monthlyPayablePrice;
    totalYearlyPrice += yearlyPayablePrice;
  });

  return getDicount({
    monthlyPrice: totalMonthlyPrice,
    planPrice: totalYearlyPrice,
    planTenure: TENURES.YEARLY,
  });
};

export const getPlan = ({ featureKey, plans }) => {
  return plans.find(
    (plan) => plan[SUBSCRIPTIONS_API_KEYS.feature_key] === featureKey
  );
};

export const getTenurePlan = ({ featureKey, plans, tenure }) => {
  const selectedPlan = getPlan({ featureKey, plans });
  const { [SUBSCRIPTIONS_API_KEYS.plans]: tenurePlans } = selectedPlan;

  return tenurePlans.find(
    (plan) => plan[SUBSCRIPTIONS_API_KEYS.recurrence_months] === tenure
  );
};
