import { getRAUrlParams } from "features/Common/utils/url.utils";
import {
  SUBSCRIPTIONS_API_KEYS,
  SUBSCRIPTIONS_APIS,
  SUBSCRIPTIONS_URL_PARAMS,
} from "./MySubscriptions.constants";
import {
  MY_SUBCRIPTIONS_ROUTE_IDS,
  MY_SUBCRIPTIONS_URL_KEYS,
} from "./MySubscriptions.urlPaths";

export const SUBSCRIPTIONS_DETAILS_RESOURCE_APIS = {
  [MY_SUBCRIPTIONS_ROUTE_IDS[MY_SUBCRIPTIONS_URL_KEYS.SUBCRIPTION_DETAILS]]:
    () => {
      const featureKey = getRAUrlParams().get(
        SUBSCRIPTIONS_URL_PARAMS.feature_key
      );

      return {
        api_end_point: SUBSCRIPTIONS_APIS.SUBSCRIPTION_DETAILS,
        queries: {
          [SUBSCRIPTIONS_API_KEYS.feature_key]: featureKey,
        },
      };
    },
};
