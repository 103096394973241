import React from "react";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import classnames from "classnames";

export const PlanInfoSection = ({
  label,
  value,
  error,
  classes = { label: undefined, value: undefined },
}) => {
  return (
    <div>
      <SectionHeader label={label} className={classes?.label} />
      <div
        className={classnames(
          "tw-text-[#272522] tw-text-[22px] max-md:tw-text-lg tw-font-normal tw-leading-[normal]",
          error &&
            "tw-bg-[linear-gradient(91deg,_#E84855_-9.86%,_#993955_126.97%)] tw-bg-clip-text tw-text-transparent",
          classes?.value
        )}
      >
        {value}
      </div>
    </div>
  );
};
