import { Avatar } from "@my-scoot/component-library-legacy";
import { format, parse } from "date-fns";
import {
  DATE_MONTH_YEAR_FORMAT,
  MONTH_SHORT_DAY_YEAR_FORMAT_NON_COMMA,
} from "features/Common/modules/DateTime/DateTime.constants";
import React from "react";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { SUBSCRIPTION_DETAILS_API_KEYS } from "../constants/SubscriptionsDetailsModal.constants";
import { PlanExpiry } from "../modules/PlanExpiry/PlanExpiry";
import { PlanTitle } from "../modules/PlanTitle/PlanTitle";
import { SubscriptionStatus } from "../modules/SubscriptionStatus/SubscriptionStatus";

export const subscriptionDetailsColumnsConfig = [
  {
    headerName: "S.No",
    field: "id",
    valueFormatter: ({ id }) => id + 1,
    renderColumn: ({ id }) => <Avatar label={`${id + 1}`} processInitials />,
    fixed: "left",
    isPrimary: true,
  },
  {
    headerName: "Purchased on",
    field: SUBSCRIPTION_DETAILS_API_KEYS.purchased_on,
    valueFormatter: ({
      [SUBSCRIPTION_DETAILS_API_KEYS.purchased_on]: purchasedOn,
    }) =>
      purchasedOn
        ? format(
            new Date(parse(purchasedOn, DATE_MONTH_YEAR_FORMAT, new Date())),
            MONTH_SHORT_DAY_YEAR_FORMAT_NON_COMMA
          )
        : "N/A",
  },
  {
    headerName: "Valid Till",
    field: SUBSCRIPTION_DETAILS_API_KEYS.expiry,
    valueFormatter: PlanExpiry,
  },
  {
    headerName: "Plan",
    field: SUBSCRIPTION_DETAILS_API_KEYS.plan_title,
    valueFormatter: PlanTitle,
  },
  {
    // desktop UI
    headerName: (
      <div>
        <div className="tw-text-sm tw-leading-[normal] tw-opacity-85 tw-font-medium">
          Price
        </div>
        <div className="tw-text-xs tw-leading-[normal] tw-font-light">
          (Excluding GST)
        </div>
      </div>
    ),
    // mobile UI
    renderColumn: (record) => (
      <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
        <div>
          <div className="tw-leading-[normal] tw-font-semibold">Price:</div>
          <div className="tw-text-xs tw-leading-[normal] tw-font-light">
            (Excluding GST)
          </div>
        </div>

        <div>
          {`${getUserCurrencySymbol()} ${(
            record[SUBSCRIPTION_DETAILS_API_KEYS.amount] ?? 0
          ).toLocaleString()}`}
        </div>
      </div>
    ),
    field: SUBSCRIPTION_DETAILS_API_KEYS.amount,
    valueFormatter: ({ [SUBSCRIPTION_DETAILS_API_KEYS.amount]: amount }) =>
      `${getUserCurrencySymbol()} ${(amount ?? 0).toLocaleString()}`,
  },
  {
    headerName: "Status",
    field: SUBSCRIPTION_DETAILS_API_KEYS.status,
    valueFormatter: (record) => <SubscriptionStatus record={record} />,
    fixed: "right",
    hidden: true,
  },
];
