export const handleChangeLicenseTenure = (set) => (tenure) => {
  set((state) => ({
    ...state,
    licensesSection: { ...state.licensesSection, tenure },
  }));
};

export const handleSetPlans = (set) => (plans) => {
  set((state) => ({
    ...state,
    licensesSection: { ...state.licensesSection, plans },
  }));
};
