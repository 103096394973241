import CircularProgress from "@material-ui/core/CircularProgress";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import ExlyImage from "common/Components/ExlyImage";
import { SUBSCRIPTION_PLAN } from "constants/account.constants";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { differenceInDays, format } from "date-fns";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { checkIsDefined } from "features/Common/modules/DataTypes/utils/nanNullUndef";
import { QUOTED_DATE_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";
import { useIsDesktop } from "features/Common/modules/Viewport/utils/Viewport.utils";
import { EXPIRING_SOON_THRESHOLD } from "features/MySubscriptions/constants/MySubscriptions.constants";
import { PlanCta } from "features/MySubscriptions/constants/MySubscriptions.lazy";
import { getMySubscriptionsAssets } from "features/MySubscriptions/utils/MySubscriptions.utils";
import { useActivePlan } from "features/MySubscriptions/utils/useActivePlan";
import React from "react";
import { openPricingPage, pluralise } from "utils/Utils";
import {
  PLAN_DETAILS_API_KEYS,
  PLAN_SECTION_MOBILE_COLUMNS,
  PLAN_SECTION_TYPES,
  PLAN_SECTION_TYPES_COMPONENTS,
} from "./constants/ActivePlanSection.constants";
import { groupItems } from "./utils/ActivePlanSection.utils";
import classnames from "classnames";

export const ActivePlanSection = () => {
  const { isLoading, isSuccess, isError, data = {}, error } = useActivePlan();
  const isDesktop = useIsDesktop();

  if (isLoading) {
    return (
      <div className="tw-text-center tw-p-6">
        <CircularProgress />
      </div>
    );
  }

  if (isSuccess) {
    const {
      [PLAN_DETAILS_API_KEYS.current_plan]: {
        [PLAN_DETAILS_API_KEYS.name]: planName,
        [PLAN_DETAILS_API_KEYS.commission_percentage]: commission,
        [PLAN_DETAILS_API_KEYS.date_joined]: dateJoined,
        [PLAN_DETAILS_API_KEYS.start_datetime]: startDateTime,
        [PLAN_DETAILS_API_KEYS.end_datetime]: planEndDate,
        [PLAN_DETAILS_API_KEYS.internet_handling_percent]: ihf,
        [PLAN_DETAILS_API_KEYS.international_internet_handling_percent]:
          ihfInternational,
      },
    } = data ?? {};
    const daysRemaining = planEndDate
      ? differenceInDays(new Date(planEndDate), new Date())
      : undefined;
    const isExpiringToday = planEndDate ? daysRemaining === 0 : false;
    const isAboutToExpire = checkIsDefined(daysRemaining)
      ? daysRemaining > 0 && daysRemaining <= EXPIRING_SOON_THRESHOLD
      : false;
    const isPremium = planName === SUBSCRIPTION_PLAN.PREMIUM;
    const showPlanCta = !isPremium || isAboutToExpire || isExpiringToday;

    const planConfig = [
      {
        type: PLAN_SECTION_TYPES.PRIMARY,
        label: "Your Plan",
        value: planName,
      },
      {
        type: PLAN_SECTION_TYPES.SECONDARY,
        label: "Valid till",
        value: planEndDate
          ? format(new Date(planEndDate), QUOTED_DATE_FORMAT)
          : "",
        error: isAboutToExpire || isExpiringToday,
        hide: !planEndDate,
      },
      {
        type: PLAN_SECTION_TYPES.SECONDARY,
        label: "Plan started on",
        value: format(new Date(startDateTime), QUOTED_DATE_FORMAT),
      },
      {
        type: PLAN_SECTION_TYPES.SECONDARY,
        label: "Plan Commission",
        value: (
          <>
            {commission}%{" "}
            <Tooltip
              title={
                <div>
                  <div>Additional charges:</div>
                  <ol className="tw-pl-5">
                    <li>Domestic IHF - {ihf}%</li>
                    <li>International IHF - {ihfInternational}%</li>
                  </ol>
                </div>
              }
              color="primary"
              arrow
            >
              <InfoOutlined className="!tw-text-sm !tw-opacity-40" />
            </Tooltip>
          </>
        ),
      },
      {
        type: PLAN_SECTION_TYPES.SECONDARY,
        label: "Account created on",
        value: format(new Date(dateJoined), QUOTED_DATE_FORMAT),
      },
    ];

    const planSectionRenderConfig = groupItems({
      items: planConfig,
      chunkSize: isDesktop ? planConfig.length : PLAN_SECTION_MOBILE_COLUMNS,
    });

    return (
      <div className="tw-flex tw-justify-between tw-items-center max-md:tw-items-start max-md:tw-flex-col">
        <div className="tw-flex tw-flex-col tw-gap-2 max-md:tw-w-full">
          {planSectionRenderConfig.map(({ id, items }) => (
            <div
              key={id}
              className={classnames(
                "max-md:tw-min-h-0 max-md:tw-w-full tw-flex tw-flex-wrap tw-gap-9 max-md:tw-gap-2 tw-px-4 tw-py-3 tw-rounded-[10px] tw-border-[0.75px] tw-border-[#D9D9D9] tw-border-solid",
                items.length > 1 && "tw-justify-around"
              )}
            >
              {items.map((config, index) => {
                const Component = PLAN_SECTION_TYPES_COMPONENTS[config.type];
                return config.hide ? null : (
                  <>
                    <Component key={config.type} {...config} />

                    {index < items.length - 1 && (
                      <div className="tw-w-[0.75px] tw-bg-[#D9D9D9] tw-self-stretch" />
                    )}
                  </>
                );
              })}
            </div>
          ))}
        </div>

        <LazyLoadWrapper loadingCondition={showPlanCta}>
          <PlanCta
            text={
              isAboutToExpire || isExpiringToday ? "Renew Now" : "Upgrade plan"
            }
            startIcon={
              isAboutToExpire || isExpiringToday ? (
                <AutorenewRoundedIcon />
              ) : (
                <ExlyImage src={getMySubscriptionsAssets("arrow_bubble.svg")} />
              )
            }
            expiryLabel={
              isAboutToExpire || isExpiringToday ? (
                <>
                  {isAboutToExpire &&
                    `Only ${daysRemaining} ${pluralise(
                      "day",
                      daysRemaining
                    )} remaining`}
                  {isExpiringToday && "Plan expiring today"}
                </>
              ) : null
            }
            className="max-md:tw-mt-6"
            onClick={openPricingPage}
          />
        </LazyLoadWrapper>
      </div>
    );
  }

  if (isError) return error?.response?.data?.message || SOMETHING_WENT_WRONG;

  return null;
};
