import CachedIcon from "@material-ui/icons/Cached";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { Button } from "@my-scoot/component-library-legacy";
import { differenceInDays, format } from "date-fns";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { DATE_WITH_DAY_ORDINAL_SUFFIX } from "features/Common/modules/DateTime/DateTime.constants";
import {
  CREDIT_PAYMENT_TYPE,
  EXPIRING_SOON_THRESHOLD,
  LICENSE_TYPES,
  SUBSCRIPTIONS_API_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  WhiteLabellingLearnMoreModal,
  WhiteLabellingPurchaseModal,
} from "features/MySubscriptions/constants/MySubscriptions.lazy";
import { LicenseCard } from "features/MySubscriptions/modules/LicenseCard/LicenseCard";
import { StatusBadge } from "features/MySubscriptions/modules/LicenseCard/modules/StatusBadge/StatusBadge";
import { parseLicenseCardData } from "features/MySubscriptions/modules/LicenseCard/utils/parseLicenseCardData";
import { useMySubscriptionsStore } from "features/MySubscriptions/store/useMySubscriptionsStore";
import React from "react";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { useToggleState } from "utils/hooks";

export const WhiteLabellingLicenseCard = ({ plan, onSeeDetails }) => {
  const { getItemQuantityInCart, handleGoToCart } = useCart();
  const tenure = useMySubscriptionsStore(
    (state) => state.licensesSection.tenure
  );
  const [
    isWhiteLabellingLearnMoreModalOpen,
    openWhiteLabellingLearnMoreModal,
    closeWhiteLabellingLearnMoreModal,
  ] = useToggleState(false);
  const [
    isWhiteLabellingPurchaseModalOpen,
    openWhiteLabellingPurchaseModal,
    closeWhiteLabellingPurchaseModal,
  ] = useToggleState(false);
  const {
    [SUBSCRIPTIONS_API_KEYS.feature_consumption_config]: {
      [SUBSCRIPTIONS_API_KEYS.is_whitelabel]: isWhitelabelActive,
      [SUBSCRIPTIONS_API_KEYS.subscription_map]: subscriptionMap = {},
    },

    [SUBSCRIPTIONS_API_KEYS.plans]: plans,
  } = plan;
  const {
    [SUBSCRIPTIONS_API_KEYS.expiry]: expiryDate,
    [SUBSCRIPTIONS_API_KEYS.credit_payment_type]: creditPaymentType,
  } = subscriptionMap;
  const remainingDays = expiryDate
    ? differenceInDays(new Date(expiryDate), new Date())
    : null;
  const isExpiringSoom = remainingDays
    ? remainingDays <= EXPIRING_SOON_THRESHOLD
    : false;
  const badgeLabel = isWhitelabelActive ? "Active" : undefined;
  const isAutoDebitActive = creditPaymentType === CREDIT_PAYMENT_TYPE.RECURRING;
  const totalAddedInCart = getItemQuantityInCart(
    LICENSE_TYPES.EXLY_WHITE_LABELLING
  );

  const handleSeeDetails = () => {
    onSeeDetails({ featureKey: LICENSE_TYPES.EXLY_WHITE_LABELLING });
  };

  return (
    <div className="tw-flex-1">
      <LicenseCard
        onBuy={openWhiteLabellingPurchaseModal}
        onLearnMore={openWhiteLabellingLearnMoreModal}
        customPrimaryActionJSX={
          <>
            {badgeLabel ? (
              <StatusBadge
                label={badgeLabel}
                error={isExpiringSoom}
                onSeeDetails={handleSeeDetails}
              />
            ) : (
              <div />
            )}

            {isAutoDebitActive && (
              <div className="tw-px-2 tw-py-[2px] tw-rounded-[2px] tw-bg-[#7365CD4D] tw-text-[#5947CF] tw-text-sm tw-leading-[22px]">
                Auto - Debit Active
              </div>
            )}

            {isExpiringSoom && (
              <Button
                className="tw-max-h-9 !tw-rounded-md"
                onClick={openWhiteLabellingPurchaseModal}
                startIcon={<CachedIcon />}
              >
                Renew now
              </Button>
            )}

            {!isWhitelabelActive && (
              <>
                {totalAddedInCart ? (
                  <Button
                    color="success"
                    className="!tw-h-[33px] !tw-rounded-[5px]"
                    onClick={handleGoToCart}
                  >
                    <CheckRoundedIcon className="!tw-mr-1 !tw-text-base" />{" "}
                    Added in Cart
                  </Button>
                ) : (
                  <Button
                    className="tw-max-h-9 !tw-rounded-md"
                    onClick={openWhiteLabellingPurchaseModal}
                    startIcon={<LocalMallOutlinedIcon />}
                  >
                    Buy now
                  </Button>
                )}
              </>
            )}
          </>
        }
        {...parseLicenseCardData({ plan, tenure })}
      >
        {isExpiringSoom && (
          <div className="tw-text-sm tw-opacity-80 tw-bg-[linear-gradient(91deg,_#E84855_-9.86%,_#993955_126.97%)] tw-bg-clip-text tw-text-transparent tw-webkit-bg-clip-text tw-webkit-text-fill-transparent tw-mt-2">
            Expiring in {remainingDays} days
          </div>
        )}

        {expiryDate && (
          <div className="tw-text-[#272522] tw-mt-2">
            <span className="tw-text-[10px] tw-font-light tw-opacity-60">
              {isAutoDebitActive ? "Renews automatically" : "Expires"} on
            </span>{" "}
            <span className="tw-text-xs tw-opacity-80 tw-leading-[normal]">
              {format(new Date(expiryDate), DATE_WITH_DAY_ORDINAL_SUFFIX)}
            </span>
          </div>
        )}
      </LicenseCard>

      <LazyLoadWrapper loadingCondition={isWhiteLabellingPurchaseModalOpen}>
        <WhiteLabellingPurchaseModal
          open={isWhiteLabellingPurchaseModalOpen}
          onClose={closeWhiteLabellingPurchaseModal}
          plans={plans}
        />
      </LazyLoadWrapper>

      <LazyLoadWrapper loadingCondition={isWhiteLabellingLearnMoreModalOpen}>
        <WhiteLabellingLearnMoreModal
          open={isWhiteLabellingLearnMoreModalOpen}
          onClose={closeWhiteLabellingLearnMoreModal}
        />
      </LazyLoadWrapper>
    </div>
  );
};
