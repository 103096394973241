import { differenceInDays, format, parse } from "date-fns";
import {
  DATE_MONTH_YEAR_FORMAT,
  MONTH_SHORT_DAY_YEAR_FORMAT_NON_COMMA,
} from "features/Common/modules/DateTime/DateTime.constants";
import { EXPIRING_SOON_THRESHOLD } from "features/MySubscriptions/constants/MySubscriptions.constants";
import React from "react";
import { SUBSCRIPTION_DETAILS_API_KEYS } from "../../constants/SubscriptionsDetailsModal.constants";

export const PlanExpiry = ({
  [SUBSCRIPTION_DETAILS_API_KEYS.expiry]: expiry,
}) => {
  const parsedExpiry = parse(expiry, DATE_MONTH_YEAR_FORMAT, new Date());
  const daysRemaining = expiry
    ? differenceInDays(parsedExpiry, new Date())
    : undefined;
  const isExpiringSoon = expiry
    ? daysRemaining > 0 && daysRemaining <= EXPIRING_SOON_THRESHOLD
    : false;
  const isExpiringToday = expiry ? daysRemaining === 0 : false;

  return (
    <div>
      <div>
        {expiry
          ? format(parsedExpiry, MONTH_SHORT_DAY_YEAR_FORMAT_NON_COMMA)
          : "N/A"}
      </div>

      {(isExpiringSoon || isExpiringToday) && (
        <div className="tw-text-[#BF2600] tw-text-xs tw-leading-[normal] tw-mt-[2px]">
          {isExpiringSoon && "Expiring soon"}
          {isExpiringToday && "Expiring today"}
        </div>
      )}
    </div>
  );
};
