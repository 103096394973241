import { TENURES } from "features/MySubscriptions/constants/MySubscriptions.tenures";
import React from "react";
import { PickerItem } from "./modules/PickerItem/PickerItem";

export const TenurePicker = ({ value, discountOnYearly, onChange }) => {
  const handleChange = (value) => () => {
    onChange(value);
  };

  const TENURES_OPTIONS = [
    { id: 1, label: "Monthly", value: TENURES.MONTHLY },
    {
      id: 2,
      label: "Yearly",
      info: discountOnYearly ? `Save ${discountOnYearly}%` : undefined,
      value: TENURES.YEARLY,
    },
  ];

  return (
    <div className="tw-w-fit tw-flex tw-gap-1 tw-rounded-lg tw-bg-white tw-shadow-[0px_2px_12px_0px_rgba(73,58,177,0.20)] tw-p-1">
      {TENURES_OPTIONS.map((item) => (
        <PickerItem
          key={item.id}
          label={item.label}
          info={item.info}
          active={value === item.value}
          onClick={handleChange(item.value)}
        />
      ))}
    </div>
  );
};
