import { CircularProgress } from "@material-ui/core";
import { is_empty } from "features/Common/utils/common.utils";
import {
  LICENSE_TYPE_COMPONENTS,
  SUBSCRIPTIONS_API_KEYS,
  SUBSCRIPTIONS_URL_PARAMS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";
import { useMySubscriptionsStore } from "features/MySubscriptions/store/useMySubscriptionsStore";
import { useSubscriptionPlans } from "features/MySubscriptions/utils/useSubscriptionPlans";
import React from "react";
import { useHistory } from "react-router-dom";
import { appendUrlParams } from "utils/urlUtils";
import { SubscriptionsDetailsModal } from "../SubscriptionsDetailsModal/SubscriptionsDetailsModal";
import { TenurePicker } from "../TenurePicker/TenurePicker";
import { getOverallLicensesDicount } from "./utils/LicenseSection.utils";

export const LicensesSection = () => {
  const history = useHistory();
  const { data, isLoading, isSuccess, isError } = useSubscriptionPlans();
  const tenure = useMySubscriptionsStore(
    (state) => state.licensesSection.tenure
  );
  const setPlans = useMySubscriptionsStore((state) => state.setPlans);
  const setTenure = useMySubscriptionsStore((state) => state.setTenure);
  const [detailsModalState, setDetailModalState] = React.useState({
    open: false,
    onBuy: null,
  });

  React.useEffect(() => {
    const handleSetPlans = () => {
      if (isSuccess) {
        const { [SUBSCRIPTIONS_API_KEYS.subscription_plans]: plans } = data;
        setPlans(plans);
      }
    };

    handleSetPlans();
  }, [isSuccess, data]);

  if (isLoading) {
    return (
      <div className="tw-text-center tw-m-8">
        <CircularProgress />
      </div>
    );
  }

  if (isSuccess) {
    const { [SUBSCRIPTIONS_API_KEYS.subscription_plans]: plans } = data;

    if (is_empty(plans)) return null;

    const handleOpenDetailsModal = ({ featureKey, onBuy }) => {
      history.push({
        search: appendUrlParams("", {
          [SUBSCRIPTIONS_URL_PARAMS.feature_key]: featureKey,
        }),
      });
      setDetailModalState({ open: true, onBuy });
    };

    const handleCloseDetailsModal = () => {
      setDetailModalState({ open: false, onBuy: null });
    };

    return (
      <div className="tw-p-5 max-md:tw-mx-[-16px] tw-rounded-lg max-md:tw-rounded-none tw-bg-[linear-gradient(264deg,_rgba(238,236,249,0.80)_0%,_#E0DCF4_99.78%)]">
        <div className="tw-flex max-md:tw-flex-col tw-items-center tw-justify-between">
          <div className="max-md:tw-mb-5 tw-text-xl tw-text-[#272522] tw-font-medium tw-leading-normal">
            Take your workflow to the next level
          </div>

          <TenurePicker
            value={tenure}
            onChange={setTenure}
            discountOnYearly={getOverallLicensesDicount(plans)}
          />
        </div>

        <div className="tw-flex tw-gap-5 tw-mt-5 max-md:tw-flex-col">
          {plans.map((plan) => {
            const { [SUBSCRIPTIONS_API_KEYS.feature_key]: featureKey } = plan;
            const Component = LICENSE_TYPE_COMPONENTS[featureKey];
            return (
              <Component
                key={featureKey}
                plan={plan}
                onSeeDetails={handleOpenDetailsModal}
              />
            );
          })}
        </div>

        <SubscriptionsDetailsModal
          open={detailsModalState.open}
          onBuy={detailsModalState.onBuy}
          onClose={handleCloseDetailsModal}
        />
      </div>
    );
  }

  if (isError) return "Error loading subscription plans";
};
