import Button from "@material-ui/core/Button";
import DownloadIcon from "@material-ui/icons/GetApp";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import { getMySubscriptionsAssets } from "features/MySubscriptions/utils/MySubscriptions.utils";
import React from "react";

export const PopoverComponent = ({
  closePopover,
  onCancelAutoDebit,
  onDownloadInvoice,
  record,
}) => {
  const actionGetter = (fn) => () => {
    if (fn) fn();
    closePopover();
  };

  const handleCancelAutoDebit = () => {
    if (onCancelAutoDebit) onCancelAutoDebit(record);
  };

  const actions = [
    {
      label: "Download Invoice",
      onClick: actionGetter(onDownloadInvoice),
      icon: <DownloadIcon className="!tw-text-[#493AB1]" />,
    },
    {
      label: "Cancel Auto-Debit",
      onClick: actionGetter(handleCancelAutoDebit),
      icon: <ExlyImage src={getMySubscriptionsAssets("cancel_card.svg")} />,
    },
  ];

  return (
    <div className="tw-flex tw-flex-col">
      {actions.map(({ icon, label, onClick }, index) => (
        <Button
          key={label}
          className={classnames(
            "!tw-normal-case !tw-justify-start !tw-py-[14px] !tw-px-4",
            index < actions.length - 1 &&
              "!tw-border-solid !tw-border-b-[1px] !tw-border-b-[#E7E6E4] !tw-rounded-none"
          )}
          startIcon={icon}
          onClick={actionGetter(onClick)}
        >
          {label}
        </Button>
      ))}
    </div>
  );
};
