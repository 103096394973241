import React from "react";
import classnames from "classnames";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";

export const StatusField = ({
  icon,
  label,
  tooltip,
  classes = { root: undefined, label: undefined },
}) => {
  return (
    <div
      className={classnames(
        "tw-flex tw-items-center tw-gap-2 tw-whitespace-nowrap",
        classes.root
      )}
    >
      <div
        className={classnames(
          "tw-rounded-[2px] tw-px-2 tw-text-white tw-font-roboto tw-text-sm tw-leading-[22px] tw-w-fit tw-flex tw-items-center tw-gap-[2px]",
          classes.label
        )}
      >
        {icon} {label}
      </div>

      {tooltip && (
        <Tooltip arrow color="primary" title={tooltip}>
          <InfoOutlined className="!tw-text-base !tw-opacity-50" />
        </Tooltip>
      )}
    </div>
  );
};
