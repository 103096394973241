import IconButton from "@material-ui/core/IconButton";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useIsDesktop } from "features/Common/modules/Viewport/utils/Viewport.utils";
import React from "react";

export const ActionsAnchorComponent = ({ openPopover }) => {
  const isDesktop = useIsDesktop();

  return isDesktop ? (
    <IconButton className="!tw-text-[#493AB1] !tw-p-0" onClick={openPopover}>
      <MoreVertIcon />
    </IconButton>
  ) : (
    <div
      role="button"
      onClick={openPopover}
      className="tw-font-medium tw-text-[#493AB1] tw-underline tw-leading-[normal]"
    >
      Actions
      <ChevronRightRoundedIcon />
    </div>
  );
};
