import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { Button } from "@my-scoot/component-library-legacy";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import {
  LICENSE_TYPES,
  SUBSCRIPTIONS_API_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  ZoomLicenseLearnMoreModal,
  ZoomLicensePurchaseModal,
} from "features/MySubscriptions/constants/MySubscriptions.lazy";
import { LicenseCard } from "features/MySubscriptions/modules/LicenseCard/LicenseCard";
import { StatusBadge } from "features/MySubscriptions/modules/LicenseCard/modules/StatusBadge/StatusBadge";
import { parseLicenseCardData } from "features/MySubscriptions/modules/LicenseCard/utils/parseLicenseCardData";
import { useMySubscriptionsStore } from "features/MySubscriptions/store/useMySubscriptionsStore";
import React from "react";
import { useCart } from "ui/pages/Account/hooks/useCart";
import { useToggleState } from "utils/hooks";

export const ZoomLicenseCard = ({ plan = {}, onSeeDetails }) => {
  const { getItemQuantityInCart, handleGoToCart } = useCart();
  const tenure = useMySubscriptionsStore(
    (state) => state.licensesSection.tenure
  );
  const [
    isZoomLicensePurchaseModalOpen,
    openZoomLicensePurchaseModal,
    closeZoomLicensePurchaseModal,
  ] = useToggleState(false);
  const [
    isZoomLicenseLearmMoreModalOpen,
    openZoomLicenseLearmMoreodal,
    closeZoomLicenseLearmMoreModal,
  ] = useToggleState(false);
  const {
    [SUBSCRIPTIONS_API_KEYS.feature_consumption_config]: {
      [SUBSCRIPTIONS_API_KEYS.total_free_credits]: totalFreeCredits,
      [SUBSCRIPTIONS_API_KEYS.total_paid_credits]: totalPaidCredits,
      [SUBSCRIPTIONS_API_KEYS.expiring_soon_credits]: expiringSoonCredits,
    } = {},
    [SUBSCRIPTIONS_API_KEYS.plans]: plans = [],
  } = plan || {};
  const totalActiveCredits = totalFreeCredits + totalPaidCredits;
  const isExpiringSoon = expiringSoonCredits > 0;
  const showBadge = totalFreeCredits || totalPaidCredits || isExpiringSoon;
  const badgeLabel = expiringSoonCredits
    ? `${expiringSoonCredits} Expiring Soon`
    : `${totalActiveCredits} Active`;
  const totalAddedInCart = getItemQuantityInCart(LICENSE_TYPES.EXLY_ZOOM);

  const handleBuyLicense = () => {
    openZoomLicensePurchaseModal();
    closeZoomLicenseLearmMoreModal();
  };

  const handleSeeDetails = () => {
    onSeeDetails({
      featureKey: LICENSE_TYPES.EXLY_ZOOM,
      onBuy: handleBuyLicense,
    });
  };

  return (
    <div className="tw-flex-1">
      <LicenseCard
        onBuy={handleBuyLicense}
        onLearnMore={openZoomLicenseLearmMoreodal}
        customPrimaryActionJSX={
          <>
            {showBadge ? (
              <StatusBadge
                label={badgeLabel}
                error={isExpiringSoon}
                onSeeDetails={handleSeeDetails}
              />
            ) : (
              <div />
            )}

            {totalAddedInCart ? (
              <Button
                color="success"
                className="!tw-h-[33px] !tw-rounded-[5px]"
                onClick={handleGoToCart}
              >
                <CheckRoundedIcon className="!tw-mr-1 !tw-text-base" />{" "}
                {totalAddedInCart} Added in Cart
              </Button>
            ) : (
              <Button
                className="tw-max-h-9 !tw-rounded-md"
                startIcon={<LocalMallOutlinedIcon />}
                color={totalActiveCredits ? "secondary" : undefined}
                variant={totalActiveCredits ? "outlined" : undefined}
                onClick={handleBuyLicense}
                size={totalActiveCredits ? "small" : undefined}
              >
                {totalActiveCredits ? "Buy more license" : "Buy now"}
              </Button>
            )}
          </>
        }
        {...parseLicenseCardData({ plan, tenure })}
      ></LicenseCard>

      {isZoomLicensePurchaseModalOpen && (
        <LazyLoadWrapper loadingCondition={isZoomLicensePurchaseModalOpen}>
          <ZoomLicensePurchaseModal
            plans={plans}
            open={isZoomLicensePurchaseModalOpen}
            onClose={closeZoomLicensePurchaseModal}
          />
        </LazyLoadWrapper>
      )}

      <LazyLoadWrapper loadingCondition={isZoomLicenseLearmMoreModalOpen}>
        <ZoomLicenseLearnMoreModal
          open={isZoomLicenseLearmMoreModalOpen}
          onClose={closeZoomLicenseLearmMoreModal}
          onBuy={handleBuyLicense}
        />
      </LazyLoadWrapper>
    </div>
  );
};
