import ScheduleRoundedIcon from "@material-ui/icons/ScheduleRounded";
import { SUBSCRIPTION_STATUS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  MY_SUBCRIPTIONS_ROUTE_IDS,
  MY_SUBCRIPTIONS_URL_KEYS,
} from "features/MySubscriptions/constants/MySubscriptions.urlPaths";
import React from "react";

export const SUBSCRIPTIONS_DETAILS_PATHNAME =
  MY_SUBCRIPTIONS_ROUTE_IDS[MY_SUBCRIPTIONS_URL_KEYS.SUBCRIPTION_DETAILS];

export const SUBSRIPTIONS_DETAILS_MODAL_RA_PROPS = {
  basePath: SUBSCRIPTIONS_DETAILS_PATHNAME,
  history: {
    location: {
      pathname: SUBSCRIPTIONS_DETAILS_PATHNAME,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: SUBSCRIPTIONS_DETAILS_PATHNAME,
    search: "",
    hash: "",
  },
  match: {
    path: SUBSCRIPTIONS_DETAILS_PATHNAME,
    url: SUBSCRIPTIONS_DETAILS_PATHNAME,
    isExact: true,
    params: {},
  },

  resource: SUBSCRIPTIONS_DETAILS_PATHNAME,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const SUBSCRIPTION_DETAILS_API_KEYS = {
  amount: "amount",
  expiry: "expiry",
  type: "type",
  credit_payment_type: "credit_payment_type",
  credit_plan_id: "credit_plan_id",
  show_auto_debit_tag: "show_auto_debit_tag",
  status: "status",
  status_info_icon: "status_info_icon",
  plan_title: "plan_title",
  purchased_on: "purchased_on",
};

export const STATUS_CLASSES = {
  [SUBSCRIPTION_STATUS.ACTIVE]: "tw-bg-[#00B779]",
  [SUBSCRIPTION_STATUS.COMPLETED]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_STATUS.CANCELLED]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_STATUS.FAILED]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_STATUS.HALTED]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_STATUS.ON_HOLD]: "tw-bg-[rgb(255,171,0)]",
  [SUBSCRIPTION_STATUS.PENDING]: "tw-bg-[#BF2600]",
};

export const STATUS_ICONS = {
  [SUBSCRIPTION_STATUS.COMPLETED]: (
    <ScheduleRoundedIcon className="!tw-text-base" />
  ),
  [SUBSCRIPTION_STATUS.CANCELLED]: (
    <ScheduleRoundedIcon className="!tw-text-base" />
  ),
  [SUBSCRIPTION_STATUS.FAILED]: (
    <ScheduleRoundedIcon className="!tw-text-base" />
  ),
  [SUBSCRIPTION_STATUS.HALTED]: (
    <ScheduleRoundedIcon className="!tw-text-base" />
  ),
  [SUBSCRIPTION_STATUS.PENDING]: (
    <ScheduleRoundedIcon className="!tw-text-base" />
  ),
};

export const STATUS_LABELS = {
  [SUBSCRIPTION_STATUS.ACTIVE]: "Active",
  [SUBSCRIPTION_STATUS.COMPLETED]: "Inactive",
  [SUBSCRIPTION_STATUS.CANCELLED]: "Inactive",
  [SUBSCRIPTION_STATUS.FAILED]: "Inactive",
  [SUBSCRIPTION_STATUS.HALTED]: "Inactive",
  [SUBSCRIPTION_STATUS.ON_HOLD]: "On Hold",
  [SUBSCRIPTION_STATUS.PENDING]: "Inactive",
};
