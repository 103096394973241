import DownloadIcon from "@material-ui/icons/GetApp";
import ExlyPopover from "common/Components/ExlyPopover";
import React from "react";
import {
  form_data,
  SUBSCRIPTION_TRANSACTIONS_STATUSES,
} from "ui/pages/Account/pages/SubscriptionTransactions/subscriptionTransactions.constants";
import { ActionsAnchorComponent } from "./modules/ActionsAnchorComponent/ActionsAnchorComponent";
import { PopoverComponent } from "./modules/PopoverComponent/PopoverComponent";

export const ActionsColumn = ({ record, onCancelAutoDebit }) => {
  const {
    [form_data.invoice_url.key]: invoiceUrl,
    [form_data.status.key]: status,
  } = record;
  const isAutoDebitActive =
    status === SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_ACTIVE;

  const handleDownloadInvoice = () => {
    if (invoiceUrl) window.open(invoiceUrl);
  };

  if (isAutoDebitActive) {
    return (
      <ExlyPopover
        AnchorComponent={ActionsAnchorComponent}
        PopoverComponent={PopoverComponent}
        forceDesktopDesign={false}
        popoverComponentProps={{
          record,
          onCancelAutoDebit,
          onDownloadInvoice: handleDownloadInvoice,
        }}
        popoverProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      />
    );
  }

  return (
    <div
      className="tw-text-[#493AB1] tw-text-sm tw-font-medium tw-underline"
      role="button"
      onClick={handleDownloadInvoice}
    >
      <DownloadIcon className="!tw-text-sm !tw-mr-1" /> Invoice
    </div>
  );
};
