import classnames from "classnames";
import React from "react";

export const PickerItem = ({
  active = false,
  label = "",
  info = "",
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      role="button"
      className={classnames(
        "tw-py-[2px] tw-px-[17.5px] tw-text-center tw-flex tw-items-center tw-text-[#231D4F] tw-text-sm tw-leading-normal tw-cursor-pointer tw-transition-all tw-rounded-lg hover:tw-bg-slate-100 tw-select-none",
        active && "!tw-bg-[#5243C240]"
      )}
    >
      <div>
        <div
          className={classnames("font-medium", active && "tw-text-[#493AB1]")}
        >
          {label}
        </div>
        {info && (
          <div className="tw-text-xs tw-text-[#231D4F] tw-opacity-50 tw-mt-[2px]">
            {info}
          </div>
        )}
      </div>
    </div>
  );
};
