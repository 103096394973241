import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { getInboxNotificationsCountQueryKeys } from "./inboxQueryKeys";
import { GET_INBOX_NOTIFICATIONS_COUNT } from "../constants/inbox.api";
import { INBOX_UNREAD_COUNT_KEY } from "../constants/Inbox.constants";
import { useInboxStore } from "../store/useInboxStore";

export const useGetInboxNotificationsCount = () => {
  const params = { request_params: JSON.stringify([INBOX_UNREAD_COUNT_KEY]) };
  const setTotalUnreadNotifications = useInboxStore(
    (state) => state.actions.setTotalUnreadNotifications
  );

  return useCustomQuery({
    queryKey: getInboxNotificationsCountQueryKeys.notificationsCount,
    url: GET_INBOX_NOTIFICATIONS_COUNT,
    params,
    onSuccess: (data) => {
      setTotalUnreadNotifications(data?.inbox_unread_count);
    },
  });
};
