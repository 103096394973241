import IconButton from "@material-ui/core/IconButton";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import { checkIsFunction } from "@my-scoot/exly-react-component-lib/build/cjs/utils/common/dataTypes/function";
import ExlyTable from "common/Components/ExlyTable";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { useIsDesktop } from "features/Common/modules/Viewport/utils/Viewport.utils";
import React from "react";
import { useHistory } from "react-router-dom";
import { SUBSRIPTIONS_DETAILS_MODAL_RA_PROPS } from "./constants/SubscriptionsDetailsModal.constants";
import { SubscriptionStatus } from "./modules/SubscriptionStatus/SubscriptionStatus";
import { subscriptionDetailsColumnsConfig } from "./utils/SubscriptionsDetailsModal.columnsConfig";

export const SubscriptionsDetailsModal = ({ open, onClose, onBuy }) => {
  const isDesktop = useIsDesktop();
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
    onClose();
  };

  const handleBuy = () => {
    if (checkIsFunction(onBuy)) onBuy();
    handleClose();
  };

  return (
    <ExlyModal
      open={open}
      onClose={handleClose}
      customHeader={
        isDesktop ? (
          <></>
        ) : (
          <div className="tw-flex tw-items-center tw-gap-4 tw-p-4 tw-shadow-[0px_7px_10px_0px_rgba(209,210,214,0.30)]">
            <IconButton className="!tw-p-0" onClick={onClose}>
              <ArrowBackRoundedIcon />
            </IconButton>
            <strong>License Details</strong>
          </div>
        )
      }
      showSecondaryBtn={false}
      primaryBtnProps={{ fullWidth: true }}
      primaryBtnText={
        <>
          <LocalMallOutlinedIcon className="!tw-mr-[6px] !tw-text-xl" /> Buy
          more licenses
        </>
      }
      onPrimaryBtnClick={handleBuy}
      customFooter={onBuy ? undefined : <></>}
      className="!tw-p-0"
      modal_props={{
        backDropClickClose: true,
        primaryBtnFullWidth: true,
        modalPaperClassName: "tw-min-w-[879px]",
      }}
      backDropClickClose
      escapeKeyDownClose
    >
      <ExlyTable
        ra_props={SUBSRIPTIONS_DETAILS_MODAL_RA_PROPS}
        noExportButton
        layoutProps={{
          showFixedBars: true,
          noShadow: true,
          paddingBottom: "32px",
        }}
        columnConfig={subscriptionDetailsColumnsConfig}
        primaryKey="id"
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <SubscriptionStatus record={record} classes={{ root: "tw-mr-2" }} />
          ),
        }}
      />
    </ExlyModal>
  );
};
