import { StaffMembersLicenseCard } from "../modules/LicensesSection/modules/StaffMembersLicenseCard/StaffMembersLicenseCard";
import { WhiteLabellingLicenseCard } from "../modules/LicensesSection/modules/WhiteLabellingLicenseCard/WhiteLabellingLicenseCard";
import { ZoomLicenseCard } from "../modules/LicensesSection/modules/ZoomLicenseCard/ZoomLicenseCard";
import { getUBPFeaturesAssets } from "../utils/MySubscriptions.utils";

export const MY_SUBSCRIPTIONS_CDN_ASSETS_RELATIVE_PATH =
  "assets/vectors/my_subscriptions";
export const WHITELABELLING_CDN_ASSETS_RELATIVE_PATH = `${MY_SUBSCRIPTIONS_CDN_ASSETS_RELATIVE_PATH}/whitelabelling`;
export const UBP_FEATURES_CDN_ASSETS_RELATIVE_PATH = `assets/vectors/ubp_features`;
export const EXPIRING_SOON_THRESHOLD = 10;

export const LICENSE_TYPES = {
  EXLY_ZOOM: "exly_zoom_licenses",
  EXLY_WHITE_LABELLING: "whitelabel",
  STAFF_MEMBERS: "org_member_license",
};

export const LICENSE_ICONS = {
  [LICENSE_TYPES.EXLY_ZOOM]: getUBPFeaturesAssets("zoom.svg"),
  [LICENSE_TYPES.EXLY_WHITE_LABELLING]:
    getUBPFeaturesAssets("whitelabelling.svg"),
  [LICENSE_TYPES.STAFF_MEMBERS]: getUBPFeaturesAssets("org.svg"),
};

export const LICENSE_TYPE_LABELS = {
  [LICENSE_TYPES.EXLY_ZOOM]: "Exly Zoom License",
  [LICENSE_TYPES.EXLY_WHITE_LABELLING]: "Exly White-labelling",
  [LICENSE_TYPES.STAFF_MEMBERS]: "Staff Members",
};

export const LICENSE_TYPE_COMPONENTS = {
  [LICENSE_TYPES.EXLY_ZOOM]: ZoomLicenseCard,
  [LICENSE_TYPES.EXLY_WHITE_LABELLING]: WhiteLabellingLicenseCard,
  [LICENSE_TYPES.STAFF_MEMBERS]: StaffMembersLicenseCard,
};

export const SUBSCRIPTIONS_APIS = {
  SUBSCRIPTIONS_PLANS: "business/credits/feature/subscription/plans/paginated",
  SUBSCRIPTION_DETAILS: "business/credits/feature/subscription/view/paginated",
};

export const SUBSCRIPTIONS_URL_PARAMS = {
  feature_key: "feature_key",
};

export const SUBSCRIPTIONS_API_KEYS = {
  subscription_plans: "subscription_plans",
  priority: "priority",
  is_consumable: "is_consumable",
  is_access_type: "is_access_type",
  is_featured_consumable: "is_featured_consumable",
  feature_name: "feature_name",
  feature_key: "feature_key",
  feature_description: "feature_description",
  plans: "plans",

  // plans keys - start
  default_price: "default_price",
  default_paid_credits: "default_paid_credits",
  credit_payment_type: "credit_payment_type",
  credit_plan_id: "credit_plan_id",
  custom_price: "custom_price",
  custom_paid_credits: "custom_paid_credits",
  recurrence_months: "recurrence_months",
  plan_name: "plan_name",
  is_popular: "is_popular",
  plan_description: "plan_description",
  // plans keys - end

  feature_consumption_config: "feature_consumption_config",

  // feature_consumption_config keys - start
  total_free_credits: "total_free_credits",
  total_paid_credits: "total_paid_credits",
  expiring_soon_credits: "expiring_soon_credits",
  consumed_credits: "consumed_credits",
  is_whitelabel: "is_whitelabel",
  subscription_map: "subscription_map",
  expiry: "expiry",
  // feature_consumption_config keys - end
};

export const CREDIT_PAYMENT_TYPE = {
  ONE_TIME: 1,
  RECURRING: 2,
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 0,
  PENDING: 1,
  HALTED: 2,
  CANCELLED: 3,
  COMPLETED: 4,
  FAILED: 5,
  ON_HOLD: 22,
};

export const FEATURE_CREDITS_API_KEYS = {
  change_state: "change_state",
  default_price: "default_price",
  feature_name: "feature_name",
  feature_key: "feature_key",
  is_access_type: "is_access_type",
  credit_purchase_plan: "credit_purchase_plan",
  price: "price",
  units: "units",
  unit_name: "unit_name",
  custom_price: "custom_price",
  custom_paid_credits: "custom_paid_credits",
  custom_price_expiry: "custom_price_expiry",
  credit_payment_type: "credit_payment_type",
  credit_plan_id: "credit_plan_id",
  warning_percent: "warning_percent",
  error_percent: "error_percent",
  free_credits: "free_credits",
  allotted: "allotted",
  consumed: "consumed",
  id: "id",
  quantity: "quantity",
  active_staff_count: "active_staff_count",
  total_credits: "total_credits",
};

export const CREDITS_EXPIRY_STATUS = {
  active: 1,
  expired: 2,
  warning: 3,
};

export const FEATURE_KEYS = {
  whatsapp_blast: {
    key: "whatsapp_blast",
  },
  email_marketing: {
    key: "email_marketing",
  },
  automated_email: {
    key: "automated_email",
  },
  cash_upload_gmv: {
    key: "cash_upload_gmv",
  },
  automated_whatsapp: {
    key: "automated_whatsapp",
  },
  automated_sms: {
    key: "automated_sms",
  },
  org_member_license: {
    key: LICENSE_TYPES.STAFF_MEMBERS,
  },
  zoom_license: {
    key: LICENSE_TYPES.EXLY_ZOOM,
  },
  whitelabelling: {
    key: LICENSE_TYPES.EXLY_WHITE_LABELLING,
  },
};

export const FEATURE_KEYS_CART_ICONS = {
  [FEATURE_KEYS.zoom_license.key]: getUBPFeaturesAssets("zoom.svg"),
  [FEATURE_KEYS.whitelabelling.key]: getUBPFeaturesAssets("whitelabelling.svg"),
  [FEATURE_KEYS.org_member_license.key]: getUBPFeaturesAssets("org.svg"),
  [FEATURE_KEYS.whatsapp_blast.key]: getUBPFeaturesAssets(
    "wa_marketing_messages.svg"
  ),
  [FEATURE_KEYS.cash_upload_gmv.key]: getUBPFeaturesAssets(
    "cash_upload_limit.svg"
  ),
  [FEATURE_KEYS.automated_whatsapp.key]: getUBPFeaturesAssets(
    "wa_marketing_messages.svg"
  ),
  [FEATURE_KEYS.email_marketing.key]: getUBPFeaturesAssets("mail.svg"),
  [FEATURE_KEYS.automated_email.key]: getUBPFeaturesAssets("mail.svg"),
  [FEATURE_KEYS.zoom_license.key]: getUBPFeaturesAssets("zoom.svg"),
  [FEATURE_KEYS.whitelabelling.key]: getUBPFeaturesAssets("whitelabelling.svg"),
  [FEATURE_KEYS.automated_sms.key]: getUBPFeaturesAssets("automated_sms.svg"),
};
