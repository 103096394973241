import IconButton from "@material-ui/core/IconButton";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import classnames from "classnames";
import React from "react";

export const StatusBadge = ({ onSeeDetails, label, error }) => {
  return (
    <div
      className={classnames(
        "tw-flex tw-py-2 tw-px-3 max-md:tw-px-2 tw-gap-2 tw-rounded-[18px]",
        error ? "tw-bg-[#BF260033]" : "tw-bg-[#00996633]"
      )}
    >
      <div
        className={classnames(
          "tw-size-2 tw-rounded-full tw-flex-none tw-mt-[5.5px] max-md:tw-mt-[6px]",
          error ? "tw-bg-[#BF2600]" : "tw-bg-[#009966]"
        )}
      />

      <div
        className={classnames(
          "tw-flex tw-items-center tw-gap-1 tw-text-base max-md:tw-text-sm tw-leading-[normal] tw-tracking-[0.32px]",
          error ? "tw-text-[#BF2600]" : "tw-text-[#009966]"
        )}
      >
        {label}
        <IconButton
          className="!tw-text-[#493AB1] !tw-p-0"
          onClick={onSeeDetails}
          title="See details"
        >
          <OpenInNewRoundedIcon className="max-md:!tw-text-base !tw-text-lg" />
        </IconButton>
      </div>
    </div>
  );
};
