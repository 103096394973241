import { CREDIT_PAYMENT_TYPE } from "features/MySubscriptions/constants/MySubscriptions.constants";
import React from "react";
import { SUBSCRIPTION_DETAILS_API_KEYS } from "../../constants/SubscriptionsDetailsModal.constants";

export const PlanTitle = ({
  [SUBSCRIPTION_DETAILS_API_KEYS.plan_title]: planTitle,
  [SUBSCRIPTION_DETAILS_API_KEYS.credit_payment_type]: creditPaymentType,
}) => {
  const autoRenew = creditPaymentType === CREDIT_PAYMENT_TYPE.RECURRING;

  return (
    <div>
      <div>{planTitle}</div>

      {autoRenew && (
        <div className="tw-text-xs tw-leading-[normal] tw-opacity-50 tw-mt-1">
          Renews Automatically
        </div>
      )}
    </div>
  );
};
