import { create } from "zustand";
import { TENURES } from "../constants/MySubscriptions.tenures";
import {
  handleChangeLicenseTenure,
  handleSetPlans,
} from "../utils/store.utils";

export const useMySubscriptionsStore = create((set) => ({
  licensesSection: { tenure: TENURES.MONTHLY, plans: [] },
  setTenure: handleChangeLicenseTenure(set),
  setPlans: handleSetPlans(set),
}));
