import IconButton from "@material-ui/core/IconButton";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import ExlyImage from "common/Components/ExlyImage";
import React from "react";

export const Header = ({
  icon,
  label,
  mrp,
  payablePrice,
  tenure,
  onLearnMore,
}) => {
  return (
    <div className="tw-flex tw-justify-between">
      <div className="tw-py-4 tw-px-3 tw-flex tw-gap-3 tw-items-start">
        <ExlyImage src={icon} alt={label} height={44} width={44} />

        <div className="tw-leading-[normal]">
          <div className="tw-text-[#272522] tw-text-lg tw-leading-[normal] tw-font-medium">
            {label}
          </div>

          <div className="tw-mt-2">
            <span className="tw-font-medium tw-text-lg tw-leading-[normal]">
              {payablePrice}
            </span>
            <span className="tw-text-base tw-leading-[normal] tw-line-through tw-opacity-30 tw-ml-1">
              {mrp}
            </span>
            <span className="tw-font-light tw-text-[10px] tw-ml-1">
              {tenure}
            </span>
          </div>
        </div>
      </div>

      <div>
        <IconButton onClick={onLearnMore} title="Learn more">
          <HelpOutlineRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
};
